<template>
  <div class="features-bg">
    <p class="icon"></p>
    <p class="title">{{$t('header.nav9')}}</p>
    <!-- <p class="features-title"></p> -->
    <div class="button-media">
      <button class="year" @click="filterByYear('2021')">2021</button>
      <button class="year" @click="filterByYear('2023')">2023</button>
    </div>
    <div class="report">
      <!-- <div class="report_cont" v-for="(item,index) in tableData" :key="index">
        <img class="report_pic" :src="item.piclist.pic" alt="">
          <p class="report_tit">{{item.media}}——{{item.name}}</p>
      </div> -->
      <div class="report_cont" v-for="(item,index) in filteredTableData" :key="index">
        <el-carousel height="180px" :interval='4000' arrow="never">
          <el-carousel-item  v-for="(i,n) in item.piclist" :key="n">
         <div class="img-box"><img :src="i.pic" alt=""></div>  
          </el-carousel-item>
        </el-carousel>
        <p class="report_tit">
         <a target="_blank" :href="item.url">{{$t('Feature.'+item.name)}}</a>
        </p>
        <p>「{{$t('Feature.'+item.media)}}」— {{$t('Feature.'+item.date)}}</p>
      </div>
      
      <!-- <div class="report_cont">
        <img class="report_pic" src="../assets/images/AM730 (karen).png" alt="">
          <p class="report_tit">AM730—— <a href="">萃聞萃語: 鄭萃雯 工作時運動</a> </p>
      </div>
      <div class="report_cont">
        <img class="report_pic" src="../assets/images/AM730 (karen).png" alt="">
          <p class="report_tit">AM730—— <a href="">萃聞萃語: 鄭萃雯 工作時運動</a> </p>
      </div>
      <div class="report_cont">
        <img class="report_pic" src="../assets/images/AM730 (karen).png" alt="">
          <p class="report_tit">AM730—— <a href="">萃聞萃語: 鄭萃雯 工作時運動</a> </p>
      </div> -->
    </div>
    <div class="scheme">
      <div class="corporate-title">
        <p class="icon"></p>
        <p class="title"> {{ $t('Activities.title4')}}</p>
      </div>
      <div class="contents">
        <p><i class="el-icon-s-flag"></i></p>
        <div class="pic">
          <img v-for="(item,index) in schemepic.slice(0,3)" :key="index" :src="item.pic" alt="" />
        </div>
        <p><i class="el-icon-s-flag"></i></p>
        <div class="pic">
         <img v-for="(item,index) in schemepic.slice(3,8)" :key="index" :src="item.pic" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MediaPop from '../components/VideoPop.vue'
export default {
  components: { MediaPop },
  data () {
    return {
      selectedYear: '2021',
      currentDate: new Date(),
      picshow: false,
      schemepic: [
        {
          pic: require('../assets/images/corporate/photo 11.webp'),
        },
        {
          pic: require('../assets/images/corporate/photo 12.webp'),
        },
        {
          pic: require('../assets/images/corporate/photo 13.webp'),
        },
        {
          pic: require('../assets/images/corporate/photo 6.webp'),
        },
        {
          pic: require('../assets/images/corporate/photo 7.jpeg'),
        },
        {
          pic: require('../assets/images/corporate/photo 8.jpg'),
        },
        {
          pic: require('../assets/images/corporate/photo 9.jpeg'),
        },
        {
          pic: require('../assets/images/corporate/photo 10.jpeg'),
        },
      ],
      tableData: [{
        date_year: '2021',
        date: 'fea_date1',
        url: 'https://www.am730.com.hk/column/Family/%E5%B7%A5%E4%BD%9C%E6%99%82%E9%81%8B%E5%8B%95-262619',
        name: 'fea_txt1',
        media: 'fea_media1',
        piclist: [{ pic: require('../assets/images/media/AM730 (karen).png') }],
      }, {
        date_year: '2021',
        date: 'fea_date2',
        url: 'https://www.hk01.com/%E7%A4%BE%E6%9C%83%E6%96%B0%E8%81%9E/610115/%E6%A9%9F%E6%A7%8B%E6%8E%A8%E5%98%89%E8%A8%B1%E8%A8%88%E5%8A%83-%E9%BC%93%E5%8B%B5%E8%81%B7%E5%A0%B4%E5%BA%B7%E9%AB%94%E6%96%87%E5%8C%96-%E6%9C%89%E4%BC%81%E6%A5%AD%E8%AE%93%E5%93%A1%E5%B7%A5%E6%8F%90%E6%97%A9%E6%94%BE%E5%B7%A5%E5%81%9A%E9%81%8B%E5%8B%95',
        name: 'fea_txt2',
        media: 'fea_media2',
        piclist: [{ pic: require('../assets/images/media/HK01.webp') },
        { pic: require('../assets/images/media/HK01 (2).png') },
        { pic: require('../assets/images/media/HK01 (3).png') }
        ],

      }, {
        date_year: '2021',
        date: 'fea_date3',
        url: 'https://hd.stheadline.com/news/columns/837/20210409/920429/%E5%B0%88%E6%AC%84-%E9%BB%9E%E7%95%AB%E4%BA%BA%E7%94%9F-%E8%AE%93%E5%B7%A5%E4%BD%9C%E6%96%87%E5%8C%96%E6%B3%A8%E5%85%A5-%E5%8B%95-%E5%8A%9B',
        name: 'fea_txt3',
        media: 'fea_media3',
        piclist: [{ pic: require('../assets/images/media/headline (Bernard).jpeg') },

        ],
      }, {
        date_year: '2021',
        date: 'fea_date4',
        url: 'https://mcusercontent.com/8a202f636c3cdf68cf4522eaf/images/944c749e-3c24-4701-a9af-ca111f707180.jpeg',
        name: 'fea_txt4',
        media: 'fea_media4',
        piclist: [{ pic: require('../assets/images/media/singtao.jpeg') },
        { pic: require('../assets/images/media/singtao cropped.jpeg') }
        ],
      },
      {
        date_year: '2021',
        date: 'fea_date5',
        url: 'https://hd.stheadline.com/news/realtime/spt/2211799/%E5%8D%B3%E6%99%82-%E9%AB%94%E8%82%B2-%E5%81%A5%E5%85%92%E8%BD%89%E5%9E%8B',
        name: 'fea_txt5',
        media: 'fea_media5',
        piclist: [{ pic: require('../assets/images/media/media2021-9-10.jpg') },

        ],
      },
      {
        date_year: '2021',
        date: 'fea_date6',
        url: 'https://www.marketscreener.com/quote/stock/THE-HONG-KONG-AND-CHINA-G-1412642/news/Hong-Kong-and-China-Gas-Towngas-recognised-as-CO-FIT-Best-Practice-Showcase-Company-for-advocati-36396358/',
        name: 'fea_txt6',
        media: 'fea_media6',
        piclist: [{pic: require('../assets/images/media/media2021-9-10(2).jpg') },

        ],
      },
       {
        date_year: '2021',
        date: 'fea_date7',
        url: 'https://www.am730.com.hk/column/%E6%96%B0%E8%81%9E/%E3%80%8A%E4%BC%81%E6%A5%AD%E3%80%8C%E4%B8%80%E3%80%8D%E8%B5%B7%E5%8B%95%E3%80%8B%E5%98%89%E8%A8%B1%E8%A8%88%E5%8A%83%E9%A0%92%E7%8D%8E%E7%A6%AE-99%E9%96%93%E5%85%AC%E5%8F%B8%E7%8D%B2%E5%98%89%E8%A8%B1-am730%E6%A6%9C%E4%B8%8A%E6%9C%89%E5%90%8D-284454',
        name: 'fea_txt7',
        media: 'fea_media7',
        piclist: [{ pic: require('../assets/images/media/media2021-9-13.jpg') },

        ],
      },
       {
        date_year: '2021',
        date: 'fea_date8',
        url: 'https://drive.google.com/file/d/1GEwwfJmoER7lqWq1VYg_0FesnsoWWJXa/view',
        name: 'fea_txt8',
        media: 'fea_media8',
        piclist: [{ pic: require('../assets/images/media/media_HK_TV.jpg') },

        ],
      },
      {
        date_year: '2023',
        date: 'fea_date9',
        url: 'https://www.i-cable.com/%E6%96%B0%E8%81%9E%E8%B3%87%E8%A8%8A/118240/%E4%BC%81%E6%A5%AD%E4%B8%80%E8%B5%B7%E5%8B%95-%E5%98%89%E8%A8%B1%E8%A8%88%E5%8A%83%E5%B1%95%E9%96%8B/',
        name: 'fea_txt9',
        media: 'fea_media9',
        piclist: [{ pic: require('../assets/images/media/Thumbnail.png')}
      
        ],
      }
      ],
      ispop: false,
      id: "id",
    }
  },
  methods: {
    picpop (id, index) {
      this.ispop = !this.ispop
      this.id = id
      // console.log(this.id);
      // if(id==this.id){
      //   this.picshow=!this.picshow
      // }

      // console.log(index);
    },
    closeme: function () {
      this.ispop = !this.ispop

    },
    filterByYear(year) {
      this.selectedYear = year;
    }
  },
  computed: {
    filteredTableData() {
      return this.tableData.filter(item => item.date_year === this.selectedYear);
    }
  },
}
</script>

<style scoped lang="scss">
.features-bg {
  margin-left: 10%;
  width: 80%;
  .icon {
    width: 40px;
    height: 40px;
    margin: 40px auto 5px;
    background: url('../assets/images/Group 11846.svg') 50%/50% no-repeat;
  }
  .title {
    font-size: 24px;
    color: #1e30f6;
    font-family: 'PingFang-Heavy';
    letter-spacing: 2px;
    line-height: 24px;
    margin-bottom: 60px;
  }

  .button-media {
    display: flex;
    justify-content: flex-start;
  }


  .year{
    cursor: pointer;
    padding: 5px 10px;
    width: 150px;
    border-radius: 10px;
    font-size: 16px;
    background-color: #fff;
    color: #1900ff;
    transition: 0.3s;
    margin-bottom: 2rem;
    border: 2px solid #1900ff;

  }

  .year:first-child {
    margin-right: 10px;
  }

  .year:hover {
    background-color: #1900ff;
    color: #fff;
  }


  .report {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    .report_cont {
      width: 30%;
      height: 350px;
      // background: #6ec8f588;
      margin-left: 20px;
      // margin-bottom: 90px;
      .img-box{
        height: 178px;
        img {
        // width: 100%;
        display: block;
        margin: auto;
        height: 100%;
       display:table-cell;
        object-fit: cover;
        object-position: right;
        // vertical-align: middle;
      }
      }
      
      .report_tit{
        padding: 10px;
        margin-top: 10px;
      a{
        text-decoration: none;
        color: black;
      }  

      }
    }
    
  }
  .scheme {
    .contents {
      width: 90%;
      margin-left: 5%;
      .pic {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        img {
          width: 31%;
          height: 230px;
          margin: 10px;
          border-radius: 5px;
         object-fit: cover;
        }
      }
      .el-icon-s-flag{
        width: 100%;
        text-align: left;
        font-size: 35px;
        height: 45px;
        padding-left: 15px;
        margin-top: 40px;
        color: #6ec9f5;
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .report {
    flex-direction: column;
    margin: auto;
    .report_cont {
      width: 90% !important;
      margin-bottom: 50px;
      border-radius: 20px;
      overflow: hidden;
      height: 370px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: left;
      }
      .report_tit{
      a{
        text-decoration: none;
        color: black;
      }  

      }
    }
    
  }
  .features-bg{
    .scheme {
      .contents {
        .pic {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: left;

          }
        }
        .el-icon-s-flag{
          width: 100%;
          text-align: left;
          font-size: 35px;
          height: 45px;
          padding-left: 15px;
          margin-top: 40px;
          color: #6ec9f5;
        }
      }
    }
  }

}
</style>